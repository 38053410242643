import { useState } from 'react';
import { useQuery } from 'react-query';
import Modal from 'react-bootstrap/Modal';
import wurd from 'wurd-react';
import * as actions from 'actions';
import config from 'config';
import Loader from 'components/loader';
import Progress from 'components/unit-progress';
import UnitTypeCard from 'components/site-detail/unit-card';
import Plan from './plan';


const cms = wurd.block('sitePlan');

export default function ({ site, unitType }) {
  const { data: positions, isLoading } = useQuery({
    queryKey: ['site-positions', site.code],
    queryFn: () => actions.sites.getPositions(site.code),
    staleTime: config.queries.site.staleTime,
  });

  const { data: unitTypes } = useQuery({
    queryKey: ['site-unitTypes', site.code],
    queryFn: () => actions.unitTypes.fetch(site.code, { limit: 150 }),
    staleTime: config.queries.site.staleTime,
  });

  const { data: units } = useQuery({
    queryKey: ['site-units', site.code, unitType?.code],
    queryFn: () => actions.units.fetch(site.code, {
      limit: 1000,
      typeId: unitType?.id || unitTypes.map(t => t.id),
      include: 'features',
    }),
    staleTime: config.queries.site.staleTime,
    enabled: !!unitType || !!unitTypes,
  });

  const [modal, setModal] = useState();

  if (isLoading) return <Loader />;

  function showUnit(unit) {
    setModal({
      content: <UnitTypeCard unit={unit} site={site} unitType={unitType} />,
      // size: 'xl',
    });
  }

  return (
    <>
      <Progress site={site} unitType={unitType} />

      <div className="overflow-hidden d-flex flex-column flex-1">
        <Plan site={site} units={units} unitType={unitType} unitTypes={unitTypes} positions={positions} showUnit={showUnit} />

        <Modal id="customFieldsModal" show={!!modal?.content} size={modal?.size} onHide={() => setModal()} dialogAs={modal?.dialogAs}>
          <button type="button" className="btn text-primary" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModal()}><i className="text-white fal fa-times fa-2x" /></button>
          <div className="modal-body">
            {modal?.content}
          </div>
        </Modal>
      </div>
    </>
  )
}