import { useState } from 'react';
import { Link } from 'react-router-dom';
import wurd from 'wurd-react';

import store from 'store';
import * as ui from 'utils/ui';

import Button from 'components/button';
import ListItemCard from 'components/list-item-card';
import FeatureList from 'components/feature-list';
import Annotations from './annotations';
import UnitList from './unit-list';


const cms = wurd.block('siteDetail');


export default function UnitTypeCard({
  unitType,
  site,
  unit,
  previewMode,
}) {
  const { invoicePeriod } = store.get('settings');
  const available = unit || unitType.availability.available > 0;
  const features = unit ? unit.features : unitType.tags;
  const unitBookingEnabled = unitType.priceRange !== undefined;

  return (
    <>
      <Annotations
        position="row_top-right"
        annotations={unitType.annotations}
      />

      <ListItemCard
        image={unitType.image}
        imageFit={unitType.imageFit}
      >

        <h3 className="text-truncate">{ui.getUnitTitle({ site, unit, unitType })}</h3>
        <h4 className="text-truncate text-muted fw-light fs-6">{ui.getUnitSubtitle({ site, unit, unitType })}</h4>

        <FeatureList iconsOnly tags={features || []} className="flex-1" />

        <div className="d-flex align-items-center justify-content-between gap-1 mt-2">
          <div className="d-flex flex-wrap align-items-baseline">
            <div>
              <Annotations
                position="price_above"
                annotations={unitType.annotations}
              />
              <div>
                {!unit && unitBookingEnabled && <span className="text-muted fw-light"><cms.Text id="unitType.priceFrom" /></span>}
                {!unitBookingEnabled && unitType.price_before && (
                  <span className="fs-5 ls-1 text-muted"><s>{ui.getPrice(unitType.price_before)}</s> </span>
                )}
                <span className="fs-4 fw-600 ls-1">{ui.getUnitPrice({ unit, type: unitType })}</span>
                <small className="text-muted fw-light"><cms.Text id={`per_${invoicePeriod}`} /></small>
              </div>
              <Annotations
                position="price_below"
                annotations={unitType.annotations}
              />
            </div>
          </div>

          {available ? (
            <Button icon="next" as={Link} to={previewMode ? '' : `/sites/${site.code}/${[unitType.code, unit?.name].filter(Boolean).join('~')}${!unit && unitBookingEnabled ? '?view=map' : ''}`} className="px-2 px-md-4">
              <cms.Text id="chooseUnit" />
            </Button>
          ) : (
            <div data-unit-type={unitType.code}><cms.Text id="notAvailable" /></div>
          )}
        </div>
        {/*{available && <UnitList site={site} unitType={unitType} setModal={setModal} unit={unit} setUnit={setUnit} />}*/}
      </ListItemCard>
    </>
  );
}