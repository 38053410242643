import { Fragment, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import wurd from 'wurd-react';
import { useMutation } from 'react-query';
import store from 'store';
import * as actions from 'actions';
import * as ui from 'utils/ui';
import useMobile from 'utils/useMobile';
import analytics from 'utils/analytics';

import Field from 'components/field';
import Dl from 'components/dl';


const cms = wurd.block('unitOrder.summary');

export const Accordion = styled.div({
  position: 'sticky',
  top: 58,
  zIndex: 3,
  width: 'min(564px, 100%)',
  '.accordion-item': {
    border: 'var(--border-light)!important',
    '@media only screen and (max-width: 564px)': {
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
      borderBottomWidth: 1,
    }
  },
  '@media only screen and (min-width: 992px)': {
    width: 400,
    marginTop: '2rem',
    borderRadius: 4,
    boxShadow: 'var(--shadow-light)',
    overflow: 'hidden',
    '.accordion-collapse': {
      paddingTop: '2rem!important'
    },
  },
  hr: {
    opacity: 1,
  }
});

const InfoCard = styled.div({
  borderTop: '1px solid #aaa4',
  borderBottom: '1px solid #aaa4',
  padding: '.5rem 0',
  display: 'flex',
  gap: '.5rem',
  margin: '.2rem 0',
  alignItems: 'center',

  img: {
    height: 70
  },
});

const PromoForm = styled.form({
  '.alert-success + &': {
    display: 'none'
  }
});

export function CollapsibleButton(props) {
  return (
    <button
      {...props}
      type="button"
      className="accordion-button collapsed d-flex"
      data-bs-toggle="collapse"
      data-bs-target="#orderSummary-details"
      aria-expanded="false"
      aria-controls="orderSummary-details"
    />
  );
}


export function PromoCode({
  site,
  unitType,
  order,
}) {
  const { unitOrder } = store.get();
  const inputRef = useRef();
  const promoMutation = useMutation((promoCode = inputRef.current.value) => {
    return actions.unitOrders.update(unitOrder.id, { promoCode })
      .then(() => {
        if (promoCode) analytics.selectPromotion({ promoCode });
        inputRef.current.form.reset();
      });
  });

  const submit = e => {
    e.preventDefault();
    return promoMutation.mutate();
  };

  useEffect(() => {
    const change = () => promoMutation.mutate();
    inputRef.current.addEventListener('change', change);
    return () => inputRef.current?.removeEventListener('change', change);
  }, []);

  return (
    <>
      {order.promoCode && (
        <div className="alert alert-success mt-2 mb-0">
          <cms.Markdown id="promoCode.applied" vars={{ promoCode: order.promoCode.toUpperCase() }} />
          {/*<button
            type="button"
            className="btn btn-link btn-lg p-0 float-end"
            onClick={() => promoMutation.mutate(null)}
          >
            <i className="far fa-times fa-4" />
          </button>*/}
        </div>
      )}
      <PromoForm onSubmit={submit}>
        <Field
          cms={cms.block('promoCode')}
          name="promoCode"
          isLoading={promoMutation.isLoading}
          error={promoMutation.error?.message}
          ref={inputRef}
        />
      </PromoForm>
    </>
  )
}


export default function UnitOrderSummary({
  site,
  unitType,
  unit,
  date,
  className,
  order,
}) {
  const { invoicePeriod } = store.get('settings');
  const isMobile = useMobile();

  const startDate = order?.startDate || date;
  const invoice = order?.invoicePreview;

  const isTaxIncluded = order?.invoicePreview?.entries.find(entry => entry.taxInc);
  const Button = isMobile ? CollapsibleButton : 'span';

  return (
    <Accordion className={`accordion accordion-flush mx-auto ${className || ''}`} id="orderSummary">
      <div className="accordion-item">
        <div
          id="orderSummary-details"
          className={`accordion-collapse p-4 collapse ${isMobile ? '' : 'show'}`}
          aria-labelledby="orderSummary-title"
          data-bs-parent="#orderSummary"
        >
          <h4><cms.Text id="title" /></h4>

          <InfoCard>
            <img src={unitType.image} alt={unitType.code} />
            <div className="flex-grow-1 d-flex flex-column">
              <strong className="fs-5">{ui.getUnitTitle({ site, unit, unitType })}</strong>
              <small className="text-muted">{ui.getLangText(site.title)}</small>
            </div>
            <div className="d-flex flex-column align-items-end">
              <strong className="ls-2">{invoice ? ui.getPrice(ui.getRent(invoice)) : ui.getUnitPrice({ unit, type: unitType })}</strong>
              <cms.Text id={`per_${invoicePeriod}`} type="small" />
            </div>
          </InfoCard>

          <div className="mt-2 lh-sm"><small className="fs-7 text-muted"><cms.Text id="date" /></small></div>
          <div><strong className="fs-5 me-2"><time dateTime={startDate}>{ui.getDate(startDate)}</time></strong></div>

          {order && (
            <>
              {/* <h4 className="fs-5 mt-4 pt-2"><cms.Text id="details" type="strong" /></h4> */}
              <hr />
              <Dl>
                {invoice?.entries?.map(entry => (
                  <Fragment key={entry.id}>
                    <dt>
                      {entry.desc}
                      {entry.type === 'prepayment' && (
                        <small className="text-muted"> <cms.Text id="prepayment" /></small>
                      )}
                      {entry.qty > 1 && (
                        <small className="text-muted"> × {entry.qty}</small>
                      )}
                    </dt>
                    <dd>{ui.getPrice(entry.taxInc ? entry.total : entry.subtotal)}</dd>
                  </Fragment>
                ))}

                {invoice.tax > 0 && !isTaxIncluded && (
                  <>
                    <dt><cms.Text id="tax" /></dt>
                    <dd>{ui.getPrice(invoice.tax)}</dd>
                  </>
                )}
              </Dl>

              <PromoCode site={site} unitType={unitType} order={order} />
            </>
          )}
        </div>

        <h2 className="accordion-header" id="orderSummary-title">
          {invoice && (
            <Button className="accordion-button d-flex">
              <span className="d-flex flex-1">
                <strong className="flex-1 fw-600">
                  <cms.Text id="total" />
                  {isTaxIncluded && invoice.tax > 0 && (
                    <div className="text-muted fs-6" style={{ fontWeight: 'normal' }}>
                      <cms.Text id="taxInc" vars={{ amount: ui.getPrice(invoice?.tax) }} />
                    </div>
                  )}
                </strong>
                <strong className={`fw-600 ${isMobile ? 'me-2' : ''}`} style={{ alignSelf: 'center' }}>{ui.getPrice(invoice.total)}</strong>
              </span>
            </Button>
          )}
        </h2>
      </div>
    </Accordion>
  );
}
